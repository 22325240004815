exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-become-a-contributor-js": () => import("./../../../src/pages/become-a-contributor.js" /* webpackChunkName: "component---src-pages-become-a-contributor-js" */),
  "component---src-pages-coming-soon-js": () => import("./../../../src/pages/coming-soon.js" /* webpackChunkName: "component---src-pages-coming-soon-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-templates-blog-insights-js": () => import("./../../../src/templates/blog-insights.js" /* webpackChunkName: "component---src-templates-blog-insights-js" */),
  "component---src-templates-blog-news-js": () => import("./../../../src/templates/blog-news.js" /* webpackChunkName: "component---src-templates-blog-news-js" */),
  "component---src-templates-blog-post-insights-js": () => import("./../../../src/templates/blog-post-insights.js" /* webpackChunkName: "component---src-templates-blog-post-insights-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

